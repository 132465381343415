<template>
    <div class="home-con">
        <van-nav-bar
          :title="$route.meta.title"
          left-text="返回"
          left-arrow
          right-text="清空"
          right-icon="replay"
          @click-right="handleClear"
          @click-left="onClickLeft"
        />
        <van-cell-group title="输入需要替换的激活码（一行一个）" title-class="cell-title">
        <van-cell title="快捷粘贴">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
                <van-button plain type="info" size="small" @click="paste">粘贴</van-button>
            </template>
        </van-cell>
        <van-field
          id="inputcode"
          v-model="codes"
          rows="5"
          autosize
          type="textarea"
          placeholder="请输入或粘贴激活码到这里"
          @input = "userInputCodes"
        />
        </van-cell-group>
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        从微信聊天界面或者excel表、txt文档里面复制已经分行的激活码，粘贴到输入框里面会自动分行
        </van-notice-bar>
        <div style="margin: 16px;">
            <van-button round block type="info" @click="handleReplace">换卡</van-button>
        </div> 
    </div>
</template>
  
  <script>
  import api from "@/api";
  import { Toast } from 'vant';
  import { Dialog } from 'vant';
  export default {
    name: 'Home',
    data(){
      return {
        user:{},
        codes:""
      }
    },
    computed:{
  
    },
    components: {
  
    },
    methods:{
        handleClear(){
          localStorage.removeItem('replaces_codes')
          this.codes = ""
          Toast.success('清除成功');
        },
        userInputCodes(i){
          //如果有内容保存到locolstorage
          console.log('userInputCodes',i);
          if(i){
            localStorage.setItem('replaces_codes',i)
          }
        },
        onClickLeft(){
          // this.$toast('点左侧')
          this.$router.push({name:'Home'})
        },
        paste(){
          console.log('paste');
          // Check if the browser supports the Clipboard API
          if (navigator.clipboard) {
            console.log('Clipboard API is supported');
            navigator.clipboard.readText()
              .then(text => {
                console.log('Text from clipboard:', text);
                //如果粘贴板没有任何内容提示
                if(!text || text.length==0){
                  Toast.fail('粘贴板没有任何内容，请先复制');
                  return;
                }
                // Paste the text from clipboard into the input field
                this.codes = text;
                this.userInputCodes(text);
                Toast.success('粘贴成功');
              })
              .catch(err => {
                console.error('Error reading text from clipboard:', err);
                Toast.fail('快捷粘贴失败,请手动粘贴');
              });
          } else {
            console.log('Clipboard API is not supported');
            const input = document.getElementById("inputcode");
            // 聚焦
            input.focus();
            try {
                const result = document.execCommand('paste');
                if(result){
                  this.userInputCodes(input.value)
                  Toast.success('粘贴成功')
                }else{
                  Toast.fail('不支持快捷粘贴,请手动粘贴')
                }
                console.log("result",result);
            } catch (e) {
              console.error(e);
              Toast.fail('出错，不支持快捷粘贴,请手动粘贴')
            }
          }
          
        },
        handleReplace(){
            if(this.codes == "" || this.codes.length<32){
                Toast.fail('请输入至少一个正确的激活码');
                return;
            }
            //使用router跳转到name为replace_code_info页面并传codes参数
            this.$router.push({name:'replace_code_info',params:{codes:this.codes}})

        },
        readOneAdmin(){
            api.admin.readOneAdmin().then(res=>{
                let r = res.data
                this.user = r.data
            })
        }
    },
    mounted(){
        this.user = this.$store.state.user
        this.readOneAdmin();
        if(localStorage.getItem('replaces_codes')){
          this.codes = localStorage.getItem('replaces_codes')
        }
    }
  }
  </script>
  <style lang="scss" scoped>
  .home-con{
    .cell-title{
      text-align: left;
    }
  
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
    .head{
      background-color: #2587ee;
      height: 48px;
      color: #fff;
      margin-bottom:15px;
    }
    .van-radio{
      margin-bottom:5px
    }
    .copy-input{
      width: 1px;
      resize: none;
      border: none;
      outline: none;
      user-select: none;
      color: transparent;
      background: transparent;
    }
  }
  </style>